#page-services{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background: url('../../assets/images/fundo_topo_blue_opacity.png') fixed;

    width: 100%;
    min-height: 100vh;

    main{
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }

    .main{
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;

        section{
            max-width: 90%;
            margin: 0 auto;

            h1{
                display: flex;
                margin: 30px 0 20px 0;
                color: var(--color-primary-lighter);

                font-size: 3rem;
            }

            p, .service-content{
                color: var(--color-primary);
            }

            .service-content{

                ul{
                    list-style: none;
    
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 10px;

                    margin-top: 20px;

                    li{
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        margin: 0 auto;
                        margin-top: 10px;

                        text-align: center;

                        width: 100%;
    
                        padding: 1.6rem;
                        color: var(--color-primary-lighter);

                        svg{
                            font-size: 10rem;
                            margin-bottom: 20px;
                        }

                        span{
                            font-weight: 700;
                            font-size: 1.7rem;
                        }

                        &:nth-child(2){ color:#3da34a; }
                        &:nth-child(3){ color:#949641; }
                        &:nth-child(4){ color:#9c51ac; }
                        &:nth-child(5){ color:#186368; }
                        &:nth-child(6){ color:#91623b; }
                        &:nth-child(7){ color:#913e64; }
                        &:nth-child(8){ color:#9c4343; }
                    }
                }
            }
        }
    }

    .contact-us{
        width: 100%;
        background-color: #e4e8f2;
        margin-top: 50px;
        padding-top: 50px;
        
        border: 0 solid var(--color-text-base) ;
        border-top-width: 1px;
        border-bottom-width: 1px;

        .contact-us-content{
            max-width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            padding-bottom: 50px;

            .contents{
                span{
                    display: flex;
                    color: var(--color-primary);
                    margin-bottom: 50px;

                    font-weight: 400;
                    font-size: 1.8rem;

                    text-align: justify;
                    line-height: 35px;
                }

                .services-link-whatsapp{
                    width: 90%;
                    height: 6.6rem;
                    background: var(--color-secundary);
                    border: 2px solid var(--color-secundary-dark);
                    border-radius: 0.8rem;
                    cursor: pointer;
                    font: 700 1.6rem Archivo;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    transition: background-color 0.2s;
                    margin: 0 auto;
                    margin-top: 3.2rem;
                    gap: 10px;
                    
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: var(--color-title-in-primary);

                        width: 100%;
                        height: 100%;

                        text-decoration: none;

                        svg{
                            font-size: 3.2rem;
                            margin-right: 8px;
                        }

                    }

                    &:hover{
                        background-color: var(--color-secundary-dark);
                    }
                }
            }

            .banner{
                display: none;
            }
        }
    }
}


@media (min-width: 700px){
    #page-services{
        .main{
            section{
                max-width: 1100px;

                h1{
                    font-size: 5rem; 
                }
    
                .service-content{
                    ul{
    
                        li{
                            width: 260px;
                            margin: 0;
                            margin-top: 10px;
                        }
    
                    }
                }
            }
        }
        .contact-us{
    
            .contact-us-content{
                max-width: 1100px;
                padding-bottom: 0;
    
                .contents{
                    span{
                        display: inline;
                        line-height: 38.5px;
                    }
                    .services-link-whatsapp{
                        height: 8.6rem;
                        width: 90%;
                        font: 700 2.2rem Archivo;
    
                        svg{
                            font-size: 5.2rem;
                            margin-right: 0;
                        }
                    }
                }

                .banner{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    a{
                        color: var(--color-primary);
                        align-self: flex-end;
                        margin-bottom: 5rem;
                        font-size: 0.9rem;
                        font-weight: 300;
                        text-decoration: none;
                        margin-right: 1.5rem;
                    }
    
                    img{
                        filter:drop-shadow(0px 0px 10px #aaa);
                    }
                }
            }
        }
    
        footer{
            height: 50px;
            width: 100%;
        }
    }
}
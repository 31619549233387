.textOpenned{
    color: #04bf58;
}
.textClosed{
    color: #490d0d;
}

.dayClosed{
    display: flex;
    flex-direction: column !important;
    line-height: 20px;

    >div{
        font-size: 1.1rem;
        color: #490d0d;
    }
}
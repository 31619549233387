#page-container {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-text-in-primary);
    background: var(--color-primary) url('../../assets/images/fundo_topo.png');

    .popup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: fixed;
        bottom: 1rem;
        justify-self: center;
        width: 95%;
        background-color: var(--color-title-in-primary);
        color: var(--color-primary);

        padding: 2rem;

        border-radius: 1rem;
        opacity: 0.9;

        z-index: 1000;

        .aviso {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            >span {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3rem;
                font-weight: bold;
                margin-left: -1rem;

                svg {
                    margin-right: 0.5rem;
                }
            }

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 1.25rem 0;
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            >a {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                text-decoration: none;

                padding: 1rem;
                margin-top: 1rem;
                border-radius: 1rem;
                cursor: pointer;
                color: var(--color-title-in-primary);
                background-color: var(--color-primary);

                >span {
                    text-indent: 0.5rem;
                }
            }
        }
    }

    header {
        text-align: center;
        height: 100%;
        margin-bottom: 2rem;

        img {
            width: 80%;
        }
    }

    main {
        justify-self: center;
        text-align: center;
        font-size: 1.3rem;
        font-family: Archivo, Verdana, Geneva, Tahoma, sans-serif;
        line-height: 4rem;
        letter-spacing: 0.1rem;
        color: var(--color-text-complement);
        font-weight: bold;
        padding-top: 20px;

        section {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 40px;

            svg {
                margin-right: 5px;
                font-size: 15px;
            }

            a {
                text-decoration: none;
                color: var(--color-text-in-primary);
            }
        }
    }

    footer {
        display: flex;
        justify-content: space-evenly;
        margin: 4.2rem 0;

        a {
            width: 25rem;
            height: 8.4rem;
            border-radius: 0.8rem;
            font: 700 1.4rem Archivo;
            margin: 1px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-decoration: none;
            color: var(--color-button-text);

            transition: background-color 0.5s;

            svg {
                font-size: 2.5rem;
                margin-bottom: 0.8rem;
            }
        }
    }

    @media (min-width: 1080px) {
        .popup {
            flex-direction: row;
            justify-content: center;

            top: 0;
            left: 0;
            width: 100%;
            height: 4rem;
            line-height: 4rem;

            padding: 0;

            border-radius: 0;

            .aviso {
                flex-direction: row;
                justify-content: center;
                align-items: center;

                margin-bottom: 0;

                >span {
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    font-size: 2.2rem;
                }

                p {
                    display: flex;
                    text-align: center;
                    text-indent: 2rem;
                }
            }

            .buttons {
                margin-left: 4rem;

                >a {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    padding: 0.5rem;
                    margin-top: 0;
                    margin-left: 0.5rem;
                    border-radius: 0.5rem;
                    cursor: pointer;
                    color: var(--color-primary);
                    background-color: var(--color-title-in-primary);

                    transition: all 0.5s;

                    >span {
                        display: none;
                    }

                    &:hover {
                        color: var(--color-title-in-primary);
                        background-color: var(--color-primary);
                    }
                }
            }
        }


        #page-landing-content {
            max-width: 1100px;

            display: grid;
            grid-template-rows: 330px 110px 1fr;
            grid-template-columns: 1fr;
            grid-template-areas:
                "logo"
                "contact"
                "buttons";

            header {
                display: flex;
                grid-area: logo;
                align-self: center;
                justify-self: center;
                margin: 0;
                width: 100vh;
                justify-content: center;
                align-items: center;

                img {
                    align-self: center;
                    width: 95%;
                    max-width: 480px;
                    height: auto;
                    /*height: 65%;*/
                }

            }

            main {
                grid-area: contact;
                justify-self: center;
                font-size: 1.8rem;
                letter-spacing: 0.1rem;
                padding-top: 0;

                section {
                    svg {
                        font-size: 20px;
                    }
                }
            }

            footer {
                grid-area: buttons;
                justify-content: center;

                a {
                    position: relative;
                    width: 20rem;
                    display: flex;
                    flex-direction: column;
                    font-size: 1.8rem;
                    justify-content: center;
                    margin: 5px;
                    overflow: hidden;

                    &::after {
                        content: '';
                        position: absolute;
                        background-color: var(--color-primary-lighter);
                        width: 30rem;
                        height: 30rem;
                        transform: scale(0);
                        transition: 0.5s;
                        border-radius: 50%;
                        z-index: 1;
                        opacity: 0.7;
                    }

                    &:hover::after {
                        transform: scale(1);
                    }

                    >svg {
                        margin-bottom: 0.4rem;
                        stroke: #ffffff !important;
                        z-index: 2;
                    }

                    span {
                        z-index: 2;
                    }
                }
            }
        }
    }
}
#page-footer{
    width: 100%;

    .page-footer-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 90%;
        margin: 0 auto;
        height: 70px;


        div:first-child{
            display: flex;
            flex: 0.8;
            flex-direction: column;
            justify-content: center;
            
            height: 70px;

            color: var(--color-primary);
            font-size: 0.6rem;
            
        }

        div:nth-child(2){
            display: flex;
            flex: 1.2;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            height: 70px;
            gap: 8px;
            width: 70px;
            
            svg{
                color: var(--color-primary);
                font-size: 2rem;
            }
        }

        div:last-child{
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;

            height: 70px;

            color: var(--color-primary);
            font-size: 1rem;
        }
    }
}

@media (min-width: 700px){
    #page-footer{
        width: 100%;
    
        .page-footer-content{
            max-width: 1100px;
    
            div:first-child{
                font-size: 1.2rem;
                flex:none;
            }
    
            div:nth-child(2){
                flex: none;
                justify-content: space-between;
                width: auto;
                font-size: 1.2rem;
            }
            div:last-child{
                flex:none;
            }
        }
    }
}
#visit-us{
    width: 100%;
    min-height: 100vh;
    background: url('../../assets/images/fundo_topo_blue_opacity.png') fixed;

    main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .address-from-company{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;

            h1{
                display: flex;
                margin: 30px 0 30px 0;
                color: var(--color-primary-lighter);
                font-size: 3rem;
                text-align: center;
            }

            >div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;

                >address{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: center;

                    color: var(--color-primary);

                    svg{
                        font-size: 7.2rem;
                        margin-bottom: 20px;
                    }
                }

                >.map{
                    width: 100%;
                    height: 300px;
                    margin: 30px 0 50px 0;
    
                    footer{
                        width: 100%;
                        height: 40px;
                        text-align: center;
                        background: var(--color-primary);    
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        a{
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            color: var(--color-title-in-primary);
                            gap: 8px;
    
                            &:hover{
                                text-decoration: underline;
                            }
        
                            &:hover svg{
                                display: inline;
                            }
                        }
                    }
                }               
            }
        }

        .information{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-bottom: 50px;

            p{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 90%;

                color: var(--color-primary);
                margin: 2rem 0;
                text-align: center;

                svg{
                    font-size: 7.2rem;
                    margin-bottom: 2.5rem;
                }

                strong{
                    color: var(--color-text-message);
                }

                span a{
                    color: var(--color-primary);
                    font-weight: 700;
                }
            }
            
        }
    }
}

@media (min-width: 700px) {
    #visit-us{
            main{
                .address-from-company{
                    max-width: 1100px;
                    h1{
                        display: flex;
                        text-align: left;
                        width: 100%;
                        font-size: 4.5rem;
                    }
                    >div{
                        flex-direction: row;
                        
                        address{
                            flex: 2;
                            margin-bottom: 50px;
                            height: 360px;
                            padding-top: 50px;
                            line-height: 40px;
                            font-size: 1.7rem;
                            font-weight: 700;

                            svg{
                                font-size: 7.2rem;
                            }
                        }
                        >.map{
                            flex: 3;
                            display: flex;
                            flex-direction: column;
                            max-width: 1100px;
                            height: 370px;
                            border: 1px solid var(--color-primary);
                        }
                    }
                }
                .information{
                    p{
                        max-width: 65%;
                        text-align: center;
                        display: flex;
                        flex-direction: row;
                        gap: 10px;

                        svg{
                            font-size: 7.2rem;
                            margin-bottom: 0;
                        }

                        span{
                            display: inline-block;
                            text-align: left;
                        }
                    }
                }
        }
    }
}
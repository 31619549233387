/* Mobile */

#page-header{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 9rem;
    width: 100%;

    .header-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        max-width: 1100px;

        div{
            display: flex;
            flex-direction: column;
            flex: 1;
            
            >img{
                width: 14rem;
            }

            span{
                display: flex;
                color: var(--color-primary);
                font-size: 1.9rem;
            }
        }

        nav{
            width: auto;

            a{
                color: var(--color-primary);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 5px 10px 5px 10px;
                strong{
                    display: none;
                }

                svg{
                    font-size: 2.5rem;
                }
            }

            .selected-link{
                color: var(--color-title-in-primary);
                background: var(--color-primary);
                border-radius: 5px;
            }
        }

        
    }

    
}

@media (min-width: 700px){
    #page-header{

        height: 116px;
        .header-content{
            div{
                img{
                    width: 220px;
                }
            }
            nav{
                display: flex;
                flex-direction: row;

                a{
                    text-decoration: none;
                    font-size: 1.8rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    strong{
                        display: inline;

                    }
                    svg{
                        font-size: 2.4rem; 
                    }

                }

                
            }
        }
    }
}
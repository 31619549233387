:root{
    font-size: 60%;
    --color-background: #F0F0F7;
    --color-primary-lighter: #165999;
    --color-primary-light: #008eb7;
    --color-primary: #004c95;
    --color-primary-dark: #004688;
    --color-primary-darker: #023c74;
    --color-secundary: #04D361;
    --color-secundary-dark: #04BF58;
    --color-title-in-primary: #FFFFFF;
    --color-text-in-primary: #b0c5d8;
    --color-text-title: #32264D;
    --color-text-complement: #b7d2ec;
    --color-text-base: #99bbda;
    --color-text-in-white: #004c95;
    --color-line-in-white: #E6E6F0;
    --color-input-background: #F8F8FC;
    --color-box-footer: #FAFAFC;
    --color-border: #dcdcdc;
    --color-background-section: #e4e8f2;
    --color-text-message: #490d0d;
    
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body, #root{
    height: auto;
}

body{
    background: var(--color-background);
   
}

.animator{
    animation: aparecer 1s normal;
}

@keyframes aparecer{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

#root{
    display: flex;
    align-items: center;
    justify-content: center;  
}

body,
input,
button,
textarea{
    font: 500 1.6rem Poppins;
    color: var(--color-text-base);
}

.container{
    width: 90vw;
    max-width: 700px;
}

@media (min-width: 700px){
    :root{
        font-size: 62.5%;
    }
}
#page-contact{
    width: 100%;
    min-height: 100vh;
    background: url('../../assets/images/fundo_topo_blue_opacity.png') fixed;
    
    main{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;


        section{
            width: 90%;

            color: var(--color-primary);

            >h1{
                margin: 30px 0 20px 0;
                color: var(--color-primary-lighter);

                font-size: 2.2rem;
            }

            div{

                .qr-code{
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    a{
                        margin: 5px 0 0 0;
                    }

                    span:last-child{
                        strong{
                            color: var(--color-secundary-dark);
                        }
                    }
                }

                .contact-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: left;

                    margin-top: 30px;

                    >div{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: left;

                        margin-bottom: 28px;

                        svg{
                            font-size: 4rem;
                            margin-right: 15px ;
                        }

                        >div{
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    .message{
                        color: var(--color-text-message);
                    }
                }
            }
        }
    }
}

@media (min-width: 700px) {
    #page-contact{
        main{
            section{
                max-width: 1100px;
                

                h1{
                    width: 100%;
                    font-size: 4rem;
                }

                div{
                    display: flex;
                    flex-direction: row;

                    .qr-code{
                        flex: 1;
                        justify-content: center;
                        margin-top: 10px;
                    }

                    .contact-info{
                        flex: 1;
                    }

                }
            }
        }
    }
}
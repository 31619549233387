#page-vacation{
    width: 100%;
    min-height: 100vh;
    background: url('../../assets/images/fundo_topo_blue_opacity.png') fixed;
    
    main{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        section{
            width: 90%;

            color: var(--color-primary);

            h1{
                margin: 30px 0 20px 0;
                color: var(--color-primary-lighter);

                font-size: 2.2rem;
            }

            div{
                .days-of-vacation{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;

                    h3 {
                        margin: 3rem 0;
                    }

                    > div {
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;
                    }
                    
                    .calendar {
                        display: flex;
                        width: 28rem;
                        flex-wrap: wrap;
                        border: var(--color-primary) 2px solid;
                        border-radius: 1rem;
                        row-gap: 0.2rem;

                        outline: auto;

                        h4{
                            width: 100%;
                            text-align: center;
                            background-color: var(--color-primary);
                            color: var(--color-title-in-primary);
                            padding: 0.5rem;
                        }

                        div{
                            width: 14.27%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0.5rem 0;

                            font-size: 2rem;
                            font-weight: bold;
                        }

                        .dayShadow {
                            color: var(--color-text-in-primary);
                        }

                        .WeekDayFlag {
                            background-color: #004c95;
                        }
                    }

                    .dezember {
                        .d20{
                            border-radius: 1rem 0 0 1rem;
                        }

                        .d20, .d21, .d22, .d23, .d24, .d25, .d26, .d27, .d28, .d29, .d30, .d31{
                            background-color: var(--color-primary);
                            color: var(--color-text-in-primary);
                        }
                    }

                    .january {
                       .d1, .d2, .d3, .d4, .d5, .d6, .d7, .d8, .d9, .d10, .d11, .d12, .d13, .d14 {
                            background-color: var(--color-primary);
                            color: var(--color-text-in-primary);
                       }

                       .d14{
                            border-radius: 0 1rem 1rem 0; 
                        }

                    }
                    
                }

                .contact-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: left;

                    margin-top: 30px;

                    >div{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: left;

                        margin-bottom: 28px;

                        svg{
                            font-size: 4rem;
                            margin-right: 15px ;
                        }

                        >div{
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    .message{
                        color: var(--color-text-message);
                    }
                }
            }
        }
    }
}

@media (min-width: 700px) {
    #page-vacation{
        main{
            section{
                max-width: 1100px;
                

                h1{
                    width: 100%;
                    font-size: 4rem;
                }

                >div{
                    display: flex;
                    flex-direction: row;
                    gap: 5rem;

                    .days-of-vacation{
                        width: auto;
                        > div {
                            flex-direction: row;
                        }
                    }

                    .contact-info{
                        flex: 1;
                        padding-top: 2.6rem;

                        .message{
                           align-items: flex-start;
                        }
                    }

                    

                }
            }
        }
    }
}